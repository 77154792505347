import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async';
import Phaser from 'phaser'
import irkbitig from '../../irkbitig.png';

const Page = () => {

    const createGame = () => {

        var config = {
            type: Phaser.AUTO,
            width: 800,
            height: 600,
            backgroundColor: "#d7df21",
            parent: "game",
            physics: {
                default: "arcade",
                arcade: { debug: false },
            },
            scale: {
                autoCenter: Phaser.Scale.CENTER_BOTH
            },
            scene: {
                preload: preload,
                create: create,
                update: update,
            },
        };

        new Phaser.Game(config);

        function preload() {
            this.load.image("irkbitig", irkbitig);
        }

        function create() {
            this.irkbitig = this.physics.add.sprite(400, 300, "irkbitig");

            this.add.text(10, 10, 'Project is under development period.', {
                fontFamily: 'Oswald',
                color: '#1C79BE',
                fontSize: '3.5em'
            });

        }

        function update() {

        }

    }

    useEffect(() => {
        createGame()
    })

    return (
        <div>
            <Helmet>
                <title>Irk Bitig - Fortune Teller Oracle</title>
                <meta name="description"
                    content="Irk Bitig, Doğu Türkistan’da Bin Buda Mağaraları’nda bulunmuştur. Gök-Türk tamgalarıyla 9. yüzyılda yazılmış tarihin ilk gerçek betiğidir." />
                <link rel="canonical" href="https://www.irkbitig.com/oracle" />
            </Helmet>
            <div id="game"></div>
        </div>
    )
}

export default Page
