import React from 'react'
import { Helmet } from 'react-helmet-async';

const Page = () => {
    return (
        <div>
            <Helmet>
                <title>Irk Bitig - Türklerin Kutsal Yazgı Yorumlama Betiği</title>
                <meta name="description"
                    content="Irk Bitig, Doğu Türkistan’da Bin Buda Mağaraları’nda bulunmuştur. Gök-Türk tamgalarıyla 9. yüzyılda yazılmış tarihin ilk gerçek betiğidir." />
                <link rel="canonical" href="https://www.irkbitig.com/" />
            </Helmet>
            Irk Bitig, known as the Book of Omens in English,<br/>
            is a 9th-century manuscript book on divination that was discovered in the "Library Cave" of the Mogao Caves in Dunhuang, China,<br/>
            in 1907, and is now in the collection of the British Library in London, England.<br/>
            The book is written in Old Turkic using the Old Turkic script.<br/>
            Irk Bitig has 65 pages and you can found all pages in our application and collect that as NFT.<br/>
            Connect with your Metamask wallet on Binance Smart Chain Network<br/>
        </div>
    )
}

export default Page