import React from 'react'


const Footer = () => {
    return (
        <div id="footer">Irk Bitig Non-Fungible Token and Fortune Teller Application about Omen Book
        written in Gok-Turkish alphabet at 9th century
        </div>
    )
}

export default Footer
