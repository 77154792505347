import React from 'react'
import { Switch, Route } from "react-router-dom";
import Dashboard from './Dashboard'
import Omenbook from './Omenbook'
import Oracle from './Oracle'
import Irkbitig from './Irkbitig'

const Pages = () => {
    return (
        <div id="left">
            <Switch>
                <Route exact path="/" component={Dashboard} />
                <Route path="/omenbook" component={Omenbook} />
                <Route path="/irkbitig" component={Irkbitig} />
                <Route exact path="/oracle" component={Oracle} />
                <Route exact path="/oracle/:name-:key" component={Oracle} />
                <Route exact path='*' component={Dashboard} />
            </Switch>
        </div>
    )
}

export default Pages
