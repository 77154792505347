import React from 'react'
import { Link } from "react-router-dom";

const Header = () => {
    return (
        <div id="header">
            <div id="headnots">
                <span>
                    𐱅𐰇𐰼𐰰: 𐰆𐰍𐰕: 𐰋𐰏𐰠𐰼𐰃: 𐰉𐰆𐰑𐰣: 𐰾𐰓𐰭: 𐰇𐰕𐰀: 𐱅𐰭𐰼𐰃: 𐰉𐰽𐰢𐰽𐰺: 𐰽𐰺𐰀: 𐰘𐰃𐰼:
                    𐱅𐰠𐰤𐰢𐰾𐰼: 𐱅𐰇𐰼𐰰: 𐰉𐰆𐰑𐰣: 𐰠𐰭𐰤: 𐱅𐰇𐰼𐰇𐰭𐰤: 𐰚𐰢: 𐰺𐱃𐱃𐰃: 𐰆𐰑𐰀𐰲𐰃: 𐰼𐱅𐰃:
                    𐱅𐰇𐰼𐰰: 𐰉𐰆𐰑𐰣: 𐰼𐱅𐰤
                </span>
            </div>
            <div id="headmenu">
                <ul id="menulist">
                    <li className="link">
                        <Link to="/">Irk Bitig</Link>
                    </li>
                    <li className="link">
                        <Link to="/omenbook">About Irk Bitig</Link>
                    </li>
                    <li className="link">
                        <Link to="/oracle">Oracle</Link>
                    </li>
                    <li className="link">
                        <Link to="/irkbitig">More</Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Header
