import React from 'react'
import logo from '../irkbitig.png';

const Right = () => {
  return (
    <div id="right">
      <div id="logo"><img src={logo} alt="Irk Bitig" /></div>
      <ul id="menulist">
        <li className="link">
          <a className="link" href="https://bscscan.com/address/0x010b110221097c5150a2b272287b474755b3f0e4" target="_blank" rel="noreferrer">Binance Smart Chain</a>
        </li>
        <li className="link">
          <a className="link" href="https://twitter.com/irkbitigcom" target="_blank" rel="noreferrer">Twitter</a>
        </li>
        <li className="link">
          <a className="link" href="https://t.me/irkbitigcom" target="_blank" rel="noreferrer">Telegram</a>
        </li>
        <li className="link">
          <a className="link" href="https://irkbitig.medium.com/" target="_blank" rel="noreferrer">Medium</a>
        </li>
        <li className="link">
          <a className="link" href="https://instagram.com/irkbitigcom" target="_blank" rel="noreferrer">Instagram</a>
        </li>
      </ul>
    </div>
  )
}

export default Right
