import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet-async';
import Web3 from "web3";

const Page = () => {
    const [isMetamask, setIsMetamask] = useState(false);
    //const [isMetamaskConnected, setMetamaskIsConnected] = useState(false);

    const [accounts, setAccounts] = useState([]);
    //const [network, setNetwork] = useState("none");
    const [networkType, setNetworkType] = useState("none");
    const [walletBallance, setWalletBallance] = useState(0);

    useEffect(() => {
        const handleMetaMaskChange = () => {
            window.location.reload();
        };

        const loadWeb3 = async () => {
            if (window.ethereum) {
                window.web3 = new Web3(window.ethereum);
                await window.ethereum.request({ method: "eth_requestAccounts" });
                window.ethereum.autoRefreshOnNetworkChange = false;
                setIsMetamask(true);
                //setMetamaskIsConnected(true);
                loadBlockchainData();
            } else if (window.web3) {
                window.web3 = new Web3(window.web3.currentProvider);
                setIsMetamask(true);
                //setMetamaskIsConnected(true);
                loadBlockchainData();
            } else {
                setIsMetamask(false);
                //setMetamaskIsConnected(false);
            }
            await window.ethereum.on("message", handleMetaMaskChange);
            await window.ethereum.on("disconnect", handleMetaMaskChange);
            await window.ethereum.on("accountsChanged", handleMetaMaskChange);
            await window.ethereum.on("chainChanged", handleMetaMaskChange);
        };

        const loadBlockchainData = async () => {
            const web3 = window.web3;

            const accountList = await web3.eth.getAccounts();
            //const networkId = await web3.eth.net.getId();
            const networkType = await web3.eth.net.getNetworkType();

            let balanceWei = await web3.eth.getBalance(accountList[0]);
            let balanceEth = await web3.utils.fromWei(balanceWei, "ether");

            setAccounts(accountList);
            setWalletBallance(balanceEth);
            //setNetwork(networkId);
            setNetworkType(networkType);
        };

        loadWeb3();
    }, []);

    return (
        <div>
            <Helmet>
                <title>Irk Bitig DaPP - Omen Book - Real Fortune Teller DaPP</title>
                <meta name="description"
                    content="Irk Bitig DaPP, Irk Bitig Omen Book Token, Irk Bitig, Doğu Türkistan’da Bin Buda Mağaraları’nda bulunmuştur. Gök-Türk tamgalarıyla 9. yüzyılda yazılmış tarihin ilk gerçek betiğidir." />
                <link rel="canonical" href="https://www.irkbitig.com/irkbitig" />
            </Helmet>
            <div>{isMetamask ? "You connected Irk Bitig with Metamask wallet!" : "Use MetaMask wallet for discover Irk Bitig. Our project is under construction, support us while this process is continuing"}</div>
            {accounts[0] ? <Support account={accounts[0]} balance={walletBallance} network={networkType} /> : ""}
        </div>
    )
}

export default Page

const Support = (props) => {

    const supportIrkBitig = async () => {
        const web3 = window.web3

        await web3.eth.sendTransaction({
            from: props.account,
            to: '0x010B110221097c5150a2B272287B474755B3F0E4',
            value: '10000000000000000'
        })

    }

    return (
        <div>
            <div>Your wallet is {props.account} at {props.network} network</div>
            <div>Wallet Balance : {props.balance} ETH</div>
            <div className="link" onClick={supportIrkBitig}>Support Irk Bitig</div>
        </div>
    )
}
