import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import './App.css';
import Header from './components/Header'
import Footer from './components/Footer'
import Right from './components/Right'
import Left from './pages'


const App = () => {
  return (
    <Router>
      <div className="container">
        <Header />
        <div id="content">
          <Right />
          <Left />
        </div>
        <Footer />
      </div>
    </Router>
  )
}

export default App
