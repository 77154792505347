import React from 'react'
import { Helmet } from 'react-helmet-async';

const Page = () => {
    return (
        <div>
            <Helmet>
                <title>Irk Bitig - About Irk Bitig Book</title>
                <meta name="description"
                    content="Irk Bitig, Doğu Türkistan’da Bin Buda Mağaraları’nda bulunmuştur. Gök-Türk tamgalarıyla 9. yüzyılda yazılmış tarihin ilk gerçek betiğidir." />
                <link rel="canonical" href="https://www.irkbitig.com/omenbook" />
            </Helmet>
            Irk Bitig, known as the Book of Omens in English, is a 9th-century manuscript book on divination that was discovered in the "Library Cave" of the Mogao Caves in Dunhuang, China, in 1907, and is now in the collection of the British Library in London, England. The book is written in Old Turkic using the Old Turkic script. Irk Bitig is the only known complete manuscript text written in the Old Turkic script and first complated book in history. It is also an important source for early Turkic mythology.
        </div>
    )
}

export default Page
